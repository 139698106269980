import { render } from "./communityList.vue?vue&type=template&id=77132ad7&scoped=true"
import script from "./communityList.vue?vue&type=script&lang=js"
export * from "./communityList.vue?vue&type=script&lang=js"

import "./communityList.vue?vue&type=style&index=0&id=77132ad7&scoped=true&lang=scss&rel=stylesheet%2Fscss"
script.render = render
script.__scopeId = "data-v-77132ad7"
/* hot reload */
if (module.hot) {
  script.__hmrId = "77132ad7"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('77132ad7', script)) {
    api.reload('77132ad7', script)
  }
  
  module.hot.accept("./communityList.vue?vue&type=template&id=77132ad7&scoped=true", () => {
    api.rerender('77132ad7', render)
  })

}

script.__file = "src/views/community/communityList.vue"

export default script