<template>
  <div class="wrapper">
         <div class="im-box" @click="toIm">
           <img src="https://static.yihu365.cn/img/h5Img/img-banner2.jpg" alt="">
         </div>
         <div class="list" v-for="(item,index) in list" @click="toDetail(item.ID,item.TYPE)">
           <img  class="pic" :src="item.HEAD_PIC_URL" alt="">
           <div class="tit">{{ item.NAME }}</div>
           <div class="sub">
                <span v-for="(k,v) in item.LABEL.split('、')">{{k}}</span>
           </div>
           <div class="price">
             <div class="left">
               <p>
                 <span class="sum">￥<span>{{item.PROMOTION_PRICE}}</span>(限时特价)</span>
                 <s> ￥{{item.PRICE}}</s>
               </p>
               <p class="cont">已有{{item.SALE_NUM}}人报名</p>
             </div>
             <div class="btn">
               去学习
             </div>
           </div>
         </div>
    <div class="bm" @click="toList">
      <span>我的</span>
      <span>报名</span>
    </div>
  </div>
</template>
<script>
import {reactive,ref} from 'vue';
import {useStore} from 'vuex'
import homeApi from '@axios/home'
import {useRoute, useRouter} from "vue-router";
export default {
  name: "communityList",
  setup(){
    let router = useRouter()
    const store = useStore()
    const route = useRoute()


    const list = ref('')
    const getList = () => {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityProduct",
        newversion:'2120',
        source:'h5'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res)=> {
        console.log(res.data);
        list.value = res.data;
      })
    }
    getList()

    const toDetail = (id,type)=> {
      if(type == '2'){
        router.push({
          name: 'communityDetail',
          query: {
            productId : id
          }
        })
        return;
      }

      if(type == '3'){
        router.push({
          name: 'communityVideos',
          query: {
            productId : id
          }
        })
        return;
      }
      router.push({
        name: 'communityDetails',
        query: {
          productId : id
        }
      })
    }
    const toList =() => {
      router.push({
        name: 'communityOrderList',
        query: {

        }
      })

    }
    const toIm =()=> {

        window.location.href = `https://yhim.yihu365.cn?token=${store.state.token}&userId=${store.state.uid}`;


    }
    return {
       list,
      toList,
      toDetail,
      toIm
    }
  }
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
   .wrapper{
     font-family: STHeiti,Microsoft YaHei,Helvetica,Arial,sans-serif !important;
     //background: linear-gradient(#60A3FF 0%,#E1F6FF 20%);
     background: #f6f6f6;
     //background-image: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%);
     box-sizing: border-box;
     padding:40px 30px 160px 30px;
     .im-box{
       border-radius: 18px;
       padding-bottom: 26px;
        img{
          width: 100%;
          border-radius: 18px;
        }
     }
     .list{
       color:#323232;
       background: #FFFFFF;
       box-sizing: border-box;
       border-radius: 18px;
       margin-bottom: 30px;
       padding-bottom: 16px;
       .tit{
         margin:16px 0 20px 30px;
       }
       .sub{
         display: flex;
         margin:10px 0 10px 30px;
         span{
           display: block;
           margin-right: 16px;
           border-radius: 6px;
           font-size: 26px;
           box-sizing: border-box;
           padding:8px 14px;
           color:#00C291;
           background: #e6f9f4;
         }
       }
       .price{
         display: flex;
         justify-content: space-between;
         margin:20px 20px 20px 30px;
         p{
           margin: 0;
           font-size: 28px;
           .sum{
             color:#00C291;
             span{
               font-size: 44px;
             }
           }
           s{
            color: #999999;
             margin-left: 12px;
           }

         }
         .cont{
           margin-top: 20px;
           color:#999;
           font-size: 26px;
         }
         .btn{
           width: 100px;
           height:65px;
           line-height: 65px;

           background-image: linear-gradient(to right,  #00c291 0%,#19c89c 20%,#4dd4b2 100%);
           color:#fff;
           padding:0px 26px;
           border-radius: 32px;
           font-size: 32px;

         }
       }
     }
    .pic{
      width: 100%;
      border-radius: 18px 18px 0 0;
    }
   }
   .bm{
     position: fixed;
     left: 40px;
     bottom:30%;
     font-size: 29px;
     background-image: linear-gradient(to right,  #00c291 0%,#19c89c 20%,#4dd4b2 100%);
     width: 110px;
     height: 110px;
     text-align: center;
     box-sizing: border-box;
     border-radius: 50%;
     color:#fff;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
   }
</style>
